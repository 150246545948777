
import React from "react"
import styled, { css } from "styled-components"
import { space, color, layout, system } from "styled-system"
import { Box } from "@sqymagma/elements"

import Alert from "./svg/alert.svg"
import Arrow from "./svg/arrow.svg"
import Check from "./svg/check.svg"
import Close from "./svg/close.svg"
import CodeBrackets from "./svg/code-brackets.svg"
import Colors from "./svg/colors.svg"
import Comments from "./svg/comments.svg"
import Direction from "./svg/direction.svg"
import External from "./svg/external.svg"
import Flow from "./svg/flow.svg"
import HeroPattern from "./svg/hero-pattern.svg"
import Instagram from "./svg/instagram.svg"
import Linkedin from "./svg/linkedin.svg"
import Medium from "./svg/medium.svg"
import ObservatorioLogo from "./svg/observatorio-logo.svg"
import OpenMenu from "./svg/open-menu.svg"
import OrbitsLarge from "./svg/orbits-large.svg"
import OrbitsOnly from "./svg/orbits-only.svg"
import Orbits from "./svg/orbits.svg"
import Orbits02 from "./svg/orbits02.svg"
import PlanetDarkBlue from "./svg/planet-dark-blue.svg"
import PlanetGolden from "./svg/planet-golden.svg"
import PlanetHiddenSide from "./svg/planet-hidden-side.svg"
import PlanetLightBlue from "./svg/planet-light-blue.svg"
import PlanetTwoHalves from "./svg/planet-two-halves.svg"
import Plugin from "./svg/plugin.svg"
import Repo from "./svg/repo.svg"
import SocialInstagram from "./svg/social_instagram.svg"
import SocialLinkedin from "./svg/social_linkedin.svg"
import SocialMedium from "./svg/social_medium.svg"
import SocialTwitter from "./svg/social_twitter.svg"
import Stackalt from "./svg/stackalt.svg"
import Twitter from "./svg/twitter.svg"
import Web from "./svg/web.svg"

const filterProps = ["width", "height", "fill", "color", "bg", "display"]

const Base = css`
  *{${system({
    fill: {
      property: "fill",
      scale: "color",
    },
  })}}
`

const BaseAlert = styled(Box)
  .attrs(p => ({ as: Alert }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseArrow = styled(Box)
  .attrs(p => ({ as: Arrow }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseCheck = styled(Box)
  .attrs(p => ({ as: Check }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseClose = styled(Box)
  .attrs(p => ({ as: Close }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseCodeBrackets = styled(Box)
  .attrs(p => ({ as: CodeBrackets }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseColors = styled(Box)
  .attrs(p => ({ as: Colors }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseComments = styled(Box)
  .attrs(p => ({ as: Comments }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDirection = styled(Box)
  .attrs(p => ({ as: Direction }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseExternal = styled(Box)
  .attrs(p => ({ as: External }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseFlow = styled(Box)
  .attrs(p => ({ as: Flow }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseHeroPattern = styled(Box)
  .attrs(p => ({ as: HeroPattern }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseInstagram = styled(Box)
  .attrs(p => ({ as: Instagram }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLinkedin = styled(Box)
  .attrs(p => ({ as: Linkedin }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseMedium = styled(Box)
  .attrs(p => ({ as: Medium }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseObservatorioLogo = styled(Box)
  .attrs(p => ({ as: ObservatorioLogo }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOpenMenu = styled(Box)
  .attrs(p => ({ as: OpenMenu }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOrbitsLarge = styled(Box)
  .attrs(p => ({ as: OrbitsLarge }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOrbitsOnly = styled(Box)
  .attrs(p => ({ as: OrbitsOnly }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOrbits = styled(Box)
  .attrs(p => ({ as: Orbits }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOrbits02 = styled(Box)
  .attrs(p => ({ as: Orbits02 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlanetDarkBlue = styled(Box)
  .attrs(p => ({ as: PlanetDarkBlue }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlanetGolden = styled(Box)
  .attrs(p => ({ as: PlanetGolden }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlanetHiddenSide = styled(Box)
  .attrs(p => ({ as: PlanetHiddenSide }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlanetLightBlue = styled(Box)
  .attrs(p => ({ as: PlanetLightBlue }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlanetTwoHalves = styled(Box)
  .attrs(p => ({ as: PlanetTwoHalves }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlugin = styled(Box)
  .attrs(p => ({ as: Plugin }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseRepo = styled(Box)
  .attrs(p => ({ as: Repo }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSocialInstagram = styled(Box)
  .attrs(p => ({ as: SocialInstagram }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSocialLinkedin = styled(Box)
  .attrs(p => ({ as: SocialLinkedin }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSocialMedium = styled(Box)
  .attrs(p => ({ as: SocialMedium }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSocialTwitter = styled(Box)
  .attrs(p => ({ as: SocialTwitter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseStackalt = styled(Box)
  .attrs(p => ({ as: Stackalt }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseTwitter = styled(Box)
  .attrs(p => ({ as: Twitter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseWeb = styled(Box)
  .attrs(p => ({ as: Web }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`

const Icons = ({ name, props}) => {
  const list = {
    alert : <BaseAlert {...props} />,
arrow : <BaseArrow {...props} />,
check : <BaseCheck {...props} />,
close : <BaseClose {...props} />,
codeBrackets : <BaseCodeBrackets {...props} />,
colors : <BaseColors {...props} />,
comments : <BaseComments {...props} />,
direction : <BaseDirection {...props} />,
external : <BaseExternal {...props} />,
flow : <BaseFlow {...props} />,
heroPattern : <BaseHeroPattern {...props} />,
instagram : <BaseInstagram {...props} />,
linkedin : <BaseLinkedin {...props} />,
medium : <BaseMedium {...props} />,
observatorioLogo : <BaseObservatorioLogo {...props} />,
openMenu : <BaseOpenMenu {...props} />,
orbitsLarge : <BaseOrbitsLarge {...props} />,
orbitsOnly : <BaseOrbitsOnly {...props} />,
orbits : <BaseOrbits {...props} />,
orbits02 : <BaseOrbits02 {...props} />,
planetDarkBlue : <BasePlanetDarkBlue {...props} />,
planetGolden : <BasePlanetGolden {...props} />,
planetHiddenSide : <BasePlanetHiddenSide {...props} />,
planetLightBlue : <BasePlanetLightBlue {...props} />,
planetTwoHalves : <BasePlanetTwoHalves {...props} />,
plugin : <BasePlugin {...props} />,
repo : <BaseRepo {...props} />,
socialInstagram : <BaseSocialInstagram {...props} />,
socialLinkedin : <BaseSocialLinkedin {...props} />,
socialMedium : <BaseSocialMedium {...props} />,
socialTwitter : <BaseSocialTwitter {...props} />,
stackalt : <BaseStackalt {...props} />,
twitter : <BaseTwitter {...props} />,
web : <BaseWeb {...props} />,
    }
    return list[name]
}

const IcnSystem = ({ name, ...props }) => {
  return Icons({ name, props })
}

export { BaseAlert as Alert }
export { BaseArrow as Arrow }
export { BaseCheck as Check }
export { BaseClose as Close }
export { BaseCodeBrackets as CodeBrackets }
export { BaseColors as Colors }
export { BaseComments as Comments }
export { BaseDirection as Direction }
export { BaseExternal as External }
export { BaseFlow as Flow }
export { BaseHeroPattern as HeroPattern }
export { BaseInstagram as Instagram }
export { BaseLinkedin as Linkedin }
export { BaseMedium as Medium }
export { BaseObservatorioLogo as ObservatorioLogo }
export { BaseOpenMenu as OpenMenu }
export { BaseOrbitsLarge as OrbitsLarge }
export { BaseOrbitsOnly as OrbitsOnly }
export { BaseOrbits as Orbits }
export { BaseOrbits02 as Orbits02 }
export { BasePlanetDarkBlue as PlanetDarkBlue }
export { BasePlanetGolden as PlanetGolden }
export { BasePlanetHiddenSide as PlanetHiddenSide }
export { BasePlanetLightBlue as PlanetLightBlue }
export { BasePlanetTwoHalves as PlanetTwoHalves }
export { BasePlugin as Plugin }
export { BaseRepo as Repo }
export { BaseSocialInstagram as SocialInstagram }
export { BaseSocialLinkedin as SocialLinkedin }
export { BaseSocialMedium as SocialMedium }
export { BaseSocialTwitter as SocialTwitter }
export { BaseStackalt as Stackalt }
export { BaseTwitter as Twitter }
export { BaseWeb as Web }

export const iconList = [
  "Alert","Arrow","Check","Close","CodeBrackets","Colors","Comments","Direction","External","Flow","HeroPattern","Instagram","Linkedin","Medium","ObservatorioLogo","OpenMenu","OrbitsLarge","OrbitsOnly","Orbits","Orbits02","PlanetDarkBlue","PlanetGolden","PlanetHiddenSide","PlanetLightBlue","PlanetTwoHalves","Plugin","Repo","SocialInstagram","SocialLinkedin","SocialMedium","SocialTwitter","Stackalt","Twitter","Web"
]

export default IcnSystem
 