import React from "react"
import { Stack, Text, Box, Flex } from "@sqymagma/elements"
import NewsletterForm from "./NewsletterForm"
import { motion } from "framer-motion"
import { Orbits } from "../IconSystem"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { SplitBgStyle } from "../styles"
import { newsletterForm } from "../../data"

const NewsletterBanner = ({ revealedFooter, ...props }) => {
  const breakpoints = useBreakpoint()

  return revealedFooter || !breakpoints.m ? (
    <NewsletterContent {...props} isPortraitTablet={breakpoints.s} />
  ) : (
    <SplitBgStyle inverse={"true"}>
      <NewsletterContent {...props} isPortraitTablet={breakpoints.s} />
    </SplitBgStyle>
  )
}

export default NewsletterBanner

const NewsletterContent = ({ isPortraitTablet, ...props }) => (
  <Flex
    id="newsletter"
    color="textOnInverse01"
    pt={props.px || { default: "xl", m: "4xl", l: "5xl" }}
    pb={props.py || { default: "xl", l: "5xl" }}
    px={{ default: "m", m: "3xl", xl: "4xl" }}
    minHeight={{ default: "480px", xxl: "800px" }}
    css={`
      background: ${p => p.theme.gradient.bannerBackground};
    `}
    position="relative"
    overflow="hidden"
    alignItems="center"
    height={props.height || "auto"}
    {...props}
  >
    <Box
      maxWidth={{ default: "100%", xs: "90%", m: "60%" }}
      height="fit-content"
      mr={{ default: "m", xs: 0 }}
    >
      <Stack vs="xs" flexDirection="column" mb="xl">
        <Text as="h2" textStyle="display02" color="brand03">
          {newsletterForm.title}
        </Text>
        <Text as="p" textStyle="body" color="textOnInverse03">
          {newsletterForm.description}
        </Text>
      </Stack>

      <NewsletterForm />

      {isPortraitTablet && (
        <Flex
          position="absolute"
          top={{ default: "-50%", m: 0 }}
          right="0"
          height="100%"
          alignItems="center"
          css={`
            transform: translateX(50%);
          `}
        >
          <motion.div animate={{ rotate: 360 }} transition={{ repeat: Infinity, duration: 200 }}>
            <Orbits height={{ default: "400px", m: "600px", l: "720px" }} />
          </motion.div>
        </Flex>
      )}
    </Box>
  </Flex>
)
