import { Box, Flex } from "@sqymagma/elements"
import { Link as GatsbyLink } from "gatsby"
import { Text } from "@sqymagma/elements"
import React from "react"
import styled from "styled-components"
import { navigation } from "../../data"

const MobileNavigation = ({ inverseHeader, ...props }) => {
  return (
    <Flex role="navigation" aria-label="main" {...props} alignItems="center" minHeight="80%">
      <Box as="ul">
        {navigation.map((link, idx) => (
          <Item
            key={idx}
            inverseHeader={inverseHeader}
            label={link.label}
            last={idx === navigation.length - 1}
            to={link.internal && link.url}
            href={link.external && link.url}
            as={link.internal ? GatsbyLink : "a"}
          />
        ))}
      </Box>
    </Flex>
  )
}

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
  position: relative;
`
const Item = ({ label, last, inverseHeader, tag, newsletterLink, ...props }) => {
  return (
    <Box as="li" mr={!last && "l"} mb="l">
      <StyledLink
        textStyle="display01"
        color={inverseHeader ? "textOnInverse01" : "text01"}
        {...props}
      >
        {label}
      </StyledLink>
    </Box>
  )
}

export default MobileNavigation
