import React from "react"
import NewsletterBanner from "./NewsletterBanner"
import FooterSimple from "./FooterSimple"
import { useLocation } from "@reach/router"
import LetsMetBanner from "./LetsMetBanner"

const FooterComposed = () => {
  const { pathname } = useLocation()
  const isTrendDetail = pathname.includes("trends")
  return (
    <>
      {!isTrendDetail ? (
        <NewsletterBanner
          height={{ default: "100%" }}
          minHeight={{ default: "70vh", m: "85vh" }}
          revealedFooter={true}
        />
      ) : (
        <LetsMetBanner height={{ default: "100%" }} minHeight="85vh" />
      )}
      <FooterSimple inverse={!isTrendDetail} />
    </>
  )
}

export default FooterComposed
