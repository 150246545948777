import styled from "styled-components"
import { Box, Flex, MaxWidth } from "@sqymagma/elements"

export const SplitBgStyle = styled(Box)`
  z-index: 1;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 94.5vw;
    left: -3000px;
    background: rgb(255, 255, 255);
    background: ${p =>
      !p.inverse ? p.theme.gradient.splitBackground : p.theme.gradient.splitBackgroundInverse};
    z-index: 1;
  }
  & > div {
    margin-left: 5.5vw;
  }
`

export const CenterSpacedBox = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`
export const CenterBox = styled(Flex)`
  align-items: center;
  justify-content: center;
`

export const TopLineContainer = styled(MaxWidth)`
  &:before {
    content: "";
    margin-left: -32px;
    margin-right: -32px;
    border-top: 1px solid ${p => p.theme.color.line01};
    padding-bottom: ${p => p.theme.spacing["l"]};
  }
`
