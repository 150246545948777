import { Box, Flex, Text } from "@sqymagma/elements"
import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Alert } from "../IconSystem"
import { validateEmail } from "../utils"
import Button from "./Button"
import SiteLink from "./SiteLink"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

const StyledInput = styled(Text)`
  margin-bottom: ${p => p.theme.spacing.l};
  p {
    margin-bottom: ${p => p.theme.spacing.xs};
    color: ${p => p.theme.color.textOnInverse01};
  }
  input {
    border-radius: 0;
    height: ${p => p.theme.spacing.input.height};
    padding: 0 ${p => p.theme.spacing.input.padding};
    background-color: ${p => p.theme.color.ui04 + "10"};
    box-shadow: none;
    border: 1px solid ${p => p.theme.color.ui04};
    color: ${p => p.theme.color.textOnInverse01};

    &::placeholder {
      color: ${p => p.theme.color.textOnInverse04};
    }
    &:focus {
      box-shadow: inset 0 0 0 1px black, 0 0 0 2px ${p => p.theme.color.ui05};
      outline: none;
    }
  }
`

const NewsletterForm = () => {
  const [email, setEmail] = useState({ value: "", error: "" })
  const [consent, setConsent] = useState({ value: false, error: "" })
  const [status, setStatus] = useState({})
  const breakpoints = useBreakpoint()

  const submitForm = e => {
    e.preventDefault()

    setStatus({})
    setEmail({
      ...email,
      error: !email.value
        ? "Este campo es requerido"
        : !validateEmail(email.value) && "Formato incorrecto",
    })
    setConsent({
      ...consent,
      error: !consent.value ? "Este campo es requerido" : false,
    })

    if (email.error || consent.error || !email.value || !consent.value) return
    setStatus({ loading: true })

    addToMailchimp(email.value, {
      email: email.value,
      segment: "Newsletter",
    }).then(sendingData => {
      setStatus({ loading: false, ...sendingData })
    })
  }

  // Add attribute to url as side effect if form sending was sucessful
  const { pathname } = useLocation()
  useEffect(() => {
    status.result === "success" &&
      navigate(pathname + "?newsletter=thanks", { state: { preserveScroll: true } })
  }, [status.result, pathname])

  const Circle = ({ r, scale, delay, ...props }) => (
    <Box {...props}>
      <motion.svg
        height="100"
        width="100"
        animate={{ scale }}
        transition={{
          repeat: 100,
          repeatType: "reverse",
          duration: 1.5,
          delay,
        }}
      >
        <circle cx={"50"} cy="50" r={r} stroke-width="0" fill="white" opacity="0.2" />
      </motion.svg>
    </Box>
  )

  const Loading = () => {
    return (
      <Flex
        css={`
          border-top: 1px solid ${p => p.theme.color.ui05};
          border-bottom: 1px solid ${p => p.theme.color.ui05};
        `}
        width="100%"
        maxWidth="100%"
        py="5xl"
        justifyContent="center"
        position="relative"
      >
        <Circle r="40" scale={1.5} position="absolute" top="calc(50% - 60px)" />
        <Circle r="30" scale={1.3} position="absolute" top="calc(50% - 60px)" />
        <Circle r="10" scale={1.5} position="absolute" top="calc(50% - 60px)" />
      </Flex>
    )
  }

  return (
    <Box width="100%">
      <Flex
        width="100%"
        as="form"
        flexWrap="wrap"
        position="relative"
        alignItems="center"
        method="POST"
        onSubmit={e => submitForm(e)}
      >
        {!status.result && !status.loading && (
          <Fields
            email={email}
            setEmail={setEmail}
            consent={consent}
            setConsent={setConsent}
            status={status}
            setStatus={setStatus}
            breakpoints={breakpoints}
          />
        )}
        {status.loading && <Loading />}
        {status.result === "success" && <SendingResult success />}
        {status.result === "error" && <SendingResult error />}
      </Flex>
    </Box>
  )
}
export default NewsletterForm

const Error = ({ children }) => {
  return (
    <Text textStyle="tinyTight" display="flex" alignItems="center" mt="xxs" color="support02">
      <Alert mr="xxs" height="16px" fill="support02" />
      <Text pt="4px">{children}</Text>
    </Text>
  )
}

const Fields = ({ email, setEmail, consent, setConsent, setStatus, status, breakpoints }) => (
  <>
    <StyledInput as="label" htmlFor="email" width="100%" mb="m">
      <Text as="p" textStyle="small">
        Correo electrónico
      </Text>
      <Flex flexDirection={{ default: "column", s: "row" }}>
        <Text
          as="input"
          type="email"
          id="email"
          name="EMAIL"
          placeholder="correo@dominio.com"
          value={email.value}
          onChange={e => {
            setStatus({})
            setEmail({ ...email, value: e.target.value })
          }}
          width="100%"
        />
        <Button
          type="submit"
          value="Enviar"
          icon="arrow"
          maxWidth="200px"
          fullWidth={!breakpoints.s && true}
        />
      </Flex>
      {!email.value && email.error && <Error>{email.error}</Error>}
    </StyledInput>
    <Flex
      width="100%"
      mr={{ default: 0, m: "32px" }}
      flexDirection={{ default: "column", m: "row" }}
      alignItems={{ default: "flex-start", m: "center" }}
      justifyContent={"space-between"}
    >
      <Flex alignItems={{ default: "flex-start", m: "center" }} justifyContent={"space-between"}>
        <Text
          as="input"
          type="checkbox"
          id="rgpd"
          name="rgpd"
          mr="xxs"
          checked={consent.value}
          value={consent.value}
          onChange={_ => {
            setStatus({})
            setConsent({ ...consent, value: !consent.value })
          }}
        />
        <Text as="label" htmlFor="rgpd" textStyle="small" mr="xxs">
          Acepto la
        </Text>
        <SiteLink
          color="textOnInverse01"
          href="/politica-privacidad"
          target="_blank"
          textStyle="small"
          css={`
            text-decoration: underline;
          `}
        >
          política de privacidad
        </SiteLink>
      </Flex>
    </Flex>
    {!consent.value && consent.error && <Error>{consent.error}</Error>}
  </>
)

const SendingResult = ({ error, success }) => {
  return (
    <Flex
      css={`
        border-top: 1px solid ${p => p.theme.color.ui05};
        border-bottom: 1px solid ${p => p.theme.color.ui05};
      `}
      width="100%"
      maxWidth="100%"
      py="5xl"
      justifyContent="center"
      position="relative"
    >
      <motion.div
        initial={{
          position: "absolute",
          top: "calc(50% - 60px)",
          left: "calc(50% - 60px)",
          opacity: 0.2,
        }}
        animate={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <svg height="100" width="100">
          <circle cx="50" cy="50" r="40" stroke-width="0" fill="white" />
        </svg>
      </motion.div>
      <motion.div
        initial={{
          position: "absolute",
          top: "calc(50% - 60px)",
          left: "calc(50% - 60px)",
          opacity: 0.2,
        }}
        animate={{ left: 0, opacity: 0.8 }}
        transition={{ delay: 1, duration: 0.5, staggerChildren: 0.5 }}
      >
        <Flex>
          <Box position="relative" as="div">
            <svg height="100" width="100">
              <circle cx="50" cy="50" r="30" stroke-width="0" fill="white" opacity="0.2" />
            </svg>
            <motion.div
              initial={{
                position: "absolute",
                top: "37.5%",
                left: "calc(50% - 10px)",
                scale: 1.2,
              }}
            >
              {success && (
                <svg width="21" height="16" fill="none" viewBox="0 0 21 16">
                  <motion.path
                    initial={{ pathLength: 0, opacity: 0 }}
                    animate={{ pathLength: 3, opacity: 1 }}
                    transition={{ delay: 1.5, duration: 0.5 }}
                    d="M1 8.5L6.5 14L19.5 1"
                    stroke={"#BEA382"}
                    strokeWidth="3"
                  />
                </svg>
              )}
              {error && (
                <svg width="21" height="16" viewBox="0 0 30 30" fill="none">
                  <motion.path
                    initial={{ pathLength: 0, opacity: 0 }}
                    animate={{ pathLength: 3, opacity: 1 }}
                    transition={{ delay: 1.5, duration: 0.5 }}
                    stroke="#BEA382"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.1869 29.9999L29.999 28.1882L16.8114 14.9998L29.9999 1.81234L28.1879 0.000500113L14.9995 13.1879L1.81236 0L0.0002439 1.81172L13.1875 14.9997L0 28.1862L1.81199 29.998L14.9994 16.8117L28.1869 29.9999Z"
                    fill="#B79367"
                  />
                </svg>
              )}
            </motion.div>
          </Box>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.7, duration: 0.3 }}
          >
            {error && (
              <Text as="p" textStyle="lead" color="textOnInverse05" mr="xl" ml="l" mt="s">
                Algo ha ido mal. Por favor, inténtalo más tarde o ponte en contacto con nosotros a
                través de{" "}
                <Text
                  css={`
                    cursor: pointer;
                  `}
                  color="textOnInverse05"
                  href="mailto:observatorio@secuoyas.com"
                  target="_blank"
                >
                  observatorio@secuoyas.com
                </Text>
              </Text>
            )}
            {success && (
              <Text as="p" textStyle="lead" color="textOnInverse05" mr="xl" ml="l" mt="s">
                Hemos enviado un link de verificación a tu cuenta de correo. Haz clic en ese link
                para confirmar el proceso de suscripción. ¡Gracias!
              </Text>
            )}
          </motion.div>
        </Flex>
      </motion.div>
      <motion.div
        initial={{
          position: "absolute",
          top: "calc(50% - 60px)",
          left: "calc(50% - 60px)",
          opacity: 0.2,
        }}
        animate={{ opacity: 0 }}
        transition={{ delay: 0.25, duration: 0.5 }}
      >
        <svg height="100" width="100">
          <circle cx="50" cy="50" r="10" stroke-width="0" fill="white" />
        </svg>
      </motion.div>
    </Flex>
  )
}
