import { Link as GastbyLink } from "gatsby"
import { Text } from "@sqymagma/elements"
import React from "react"

const FormattedLink = ({ children, ...props }) => {
  return (
    <Text
      color={"link01"}
      display="inline"
      alignItems="center"
      css={`
        cursor: pointer;
        text-decoration: none;
      `}
      {...props}
    >
      {children}
    </Text>
  )
}
const SiteLink = ({ children, ...props }) => {
  return (
    <>
      {!props.to && (
        <FormattedLink as={"a"} target="_blank" {...props}>
          {children}
        </FormattedLink>
      )}

      {props.to && (
        <FormattedLink as={GastbyLink} {...props}>
          {children}
        </FormattedLink>
      )}
    </>
  )
}

export default SiteLink
