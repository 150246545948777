import { Link as GatsbyLink } from "gatsby"
import { Text } from "@sqymagma/elements"
import React from "react"
import styled, { css } from "styled-components"
import { External } from "../IconSystem"

const StyledLink = styled(Text)`
  text-decoration: none;

  ${p =>
    !p.external &&
    css`
      &:before {
        content: "";
        width: 14px;
        height: 14px;
        display: inline-flex;
        border-radius: 50%;
        background-color: ${p => p.theme.color.ui01};
        margin-right: 10px;
      }
    `}
`

const ReadMoreLink = ({ label = "Leer más", ...props }) => {
  return (
    <>
      {props.to && (
        <StyledLink
          as={props.as || GatsbyLink}
          textStyle="smallTightBold"
          color="link01"
          display="flex"
          alignItems="center"
          to={props.to}
          caps
          {...props}
        >
          {label}
          {!!props.external && <External height="16px" ml="xs" />}
        </StyledLink>
      )}

      {!props.to && (
        <StyledLink
          as={props.as || "a"}
          target="_blank"
          textStyle="smallTightBold"
          color="link01"
          display="flex"
          alignItems="center"
          caps
          {...props}
        >
          {label}
          {!!props.external && <External height="16px" ml="xs" />}
        </StyledLink>
      )}
    </>
  )
}

export default ReadMoreLink
