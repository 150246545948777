import { Box, Flex, Text } from "@sqymagma/elements"
import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Alert } from "../IconSystem"
import { validateEmail, validatePhone } from "../utils"
import Button from "./Button"
import SiteLink from "./SiteLink"
import addToMailchimp from "gatsby-plugin-mailchimp"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
import es from "react-phone-input-2/lang/es.json"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

const StyledInput = styled(Text)`
  margin-bottom: ${p => p.theme.spacing.xl};
  position: relative;
  min-width: 200px;
  flex: auto;

  p {
    margin-bottom: ${p => p.theme.spacing.xxs};
    color: ${p => p.theme.color.text01};
  }
  input {
    border-radius: 0;
    height: ${p => p.theme.spacing.input.height};
    padding: 0 ${p => p.theme.spacing.input.padding};
    background-color: ${p => p.theme.color.ui04 + "10"};
    box-shadow: none;
    border: 1px solid ${p => p.theme.color.ui04};
    color: ${p => p.theme.color.text01};

    ${p =>
      p.error &&
      css`
        margin-bottom: 16px;
      `}

    &::placeholder {
      color: ${p => p.theme.color.text06};
    }
    &:focus {
      box-shadow: inset 0 0 0 1px ${p => p.theme.color.ui04};
      outline: none;
    }
  }
`

const StyledSelect = styled(Text)`
  margin-bottom: ${p => p.theme.spacing.xl};
  position: relative;
  min-width: 200px;
  flex: auto;

  > p {
    margin-bottom: ${p => p.theme.spacing.xxs};
    color: ${p => p.theme.color.text01};
  }
  > select {
    border-radius: 0;
    height: ${p => p.theme.spacing.input.height};
    padding: 0 ${p => p.theme.spacing.input.padding};
    background: url("/select-arrow.svg") 96% center no-repeat;
    background-color: ${p => p.theme.color.ui04 + "10"};
    box-shadow: none;
    border: 1px solid ${p => p.theme.color.ui04};

    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    &:focus {
      box-shadow: inset 0 0 0 1px ${p => p.theme.color.ui04};
      outline: none;
    }
  }
`

const LetsMetForm = props => {
  const [firstname, setFirstname] = useState({ value: "", error: "" })
  const [lastname, setLastname] = useState({ value: "", error: "" })
  const [organization, setOrganization] = useState({ value: "", error: "" })
  const [email, setEmail] = useState({ value: "", error: "" })
  const [phone, setPhone] = useState({ value: "", error: "" })
  const [consent, setConsent] = useState({ value: false, error: "" })
  const [status, setStatus] = useState({})
  const organizations = [
    "Centro de Enseñanza Superior",
    "Fundación",
    "Empresa privada",
    "Organismo Público",
  ]
  const breakpoints = useBreakpoint()

  const submitForm = e => {
    e.preventDefault()

    setStatus({})
    setFirstname({
      ...firstname,
      error: !firstname.value && "Este campo es requerido",
    })
    setLastname({
      ...lastname,
      error: !lastname.value && "Este campo es requerido",
    })
    setOrganization({
      ...organization,
      error: !organization.value && "Este campo es requerido",
    })
    setOrganization({
      ...organization,
      error: !organization.value && "Este campo es requerido",
    })
    setPhone({
      ...phone,
      error: !phone.value
        ? "Este campo es requerido"
        : !validatePhone(phone.value, phone.data) && "Formato incorrecto",
    })
    setEmail({
      ...email,
      error: !email.value
        ? "Este campo es requerido"
        : !validateEmail(email.value) && "Formato incorrecto",
    })
    setConsent({
      ...consent,
      error: !consent.value ? "Este campo es requerido" : false,
    })

    if (
      firstname.error ||
      lastname.error ||
      organization.error ||
      phone.error ||
      email.error ||
      consent.error ||
      !firstname.value ||
      !lastname.value ||
      !organization.value ||
      !phone.value ||
      !email.value ||
      !consent.value
    )
      return
    setStatus({ loading: true })

    addToMailchimp(email.value, {
      fname: firstname.value,
      lname: lastname.value,
      phone: phone.value,
      email: email.value,
      typeorg: organization.value,
      segment: "Lead",
    }).then(sendingData => setStatus({ loading: false, ...sendingData }))
  }

  // Add attribute to url as side effect if form sending was sucessful
  const { pathname } = useLocation()
  useEffect(() => {
    status.result === "success" &&
      navigate(pathname + "?contact=thanks", { state: { preserveScroll: true } })
  }, [status.result, pathname])

  const Circle = ({ r, scale, delay, ...props }) => (
    <Box {...props}>
      <motion.svg
        height="100"
        width="100"
        animate={{ scale }}
        transition={{
          repeat: 100,
          repeatType: "reverse",
          duration: 1.5,
          delay,
        }}
      >
        <circle cx={"50"} cy="50" r={r} stroke-width="0" fill="#87BADE" opacity="0.2" />
      </motion.svg>
    </Box>
  )

  const Loading = () => {
    return (
      <Flex
        css={`
          border-top: 1px solid ${p => p.theme.color.ui05};
          border-bottom: 1px solid ${p => p.theme.color.ui05};
        `}
        width="100%"
        maxWidth="100%"
        py="5xl"
        justifyContent="center"
        position="relative"
      >
        <Circle r="40" scale={1.5} position="absolute" top="calc(50% - 60px)" />
        <Circle r="30" scale={1.3} position="absolute" top="calc(50% - 60px)" />
        <Circle r="10" scale={1.5} position="absolute" top="calc(50% - 60px)" />
      </Flex>
    )
  }

  return (
    <Box width="100%">
      <Flex
        width="100%"
        as="form"
        flexWrap="wrap"
        position="relative"
        alignItems="center"
        method="POST"
        onSubmit={e => submitForm(e)}
        pr={{ default: 0, m: "m" }}
      >
        {!status.result && !status.loading && (
          <Fields
            email={email}
            setEmail={setEmail}
            firstname={firstname}
            setFirstname={setFirstname}
            lastname={lastname}
            setLastname={setLastname}
            organization={organization}
            organizations={organizations}
            setOrganization={setOrganization}
            phone={phone}
            setPhone={setPhone}
            consent={consent}
            setConsent={setConsent}
            status={status}
            setStatus={setStatus}
            breakpoints={breakpoints}
          />
        )}
        {status.loading && <Loading />}
        {status.result === "success" && <SendingResult success />}
        {status.result === "error" && <SendingResult error />}
      </Flex>
    </Box>
  )
}
export default LetsMetForm

const Error = ({ children }) => {
  return (
    <Text textStyle="tinyTight" display="flex" alignItems="center" mt="xxs" color="support02">
      <Alert mr="xxs" height="16px" fill="support02" />
      <Text pt="4px">{children}</Text>
    </Text>
  )
}

const Fields = ({
  firstname,
  setFirstname,
  lastname,
  setLastname,
  email,
  setEmail,
  organization,
  organizations,
  setOrganization,
  phone,
  setPhone,
  consent,
  setConsent,
  setStatus,
  breakpoints,
}) => {
  return (
    <>
      <StyledInput
        as="label"
        htmlFor="firstname"
        mb="m"
        width={{ default: "100%", xs: "auto", s: "50%" }}
        pr={{ default: 0, xs: "m" }}
      >
        <Text as="p" textStyle="small">
          Nombre
        </Text>
        <Flex>
          <Text
            as="input"
            type="text"
            id="firstname"
            name="FNAME"
            placeholder="Nombre"
            value={firstname.value}
            onChange={e => {
              setStatus({})
              setFirstname({ ...firstname, value: e.target.value })
            }}
            width="100%"
          />
        </Flex>
        {!firstname.value && firstname.error && (
          <Box position="absolute" bottom="-24px" mt="xs">
            <Error>{firstname.error}</Error>
          </Box>
        )}
      </StyledInput>
      <StyledInput
        as="label"
        htmlFor="lastname"
        width={{ default: "100%", xs: "auto", s: "50%" }}
        pr={{ default: 0, xs: "m" }}
        mb="m"
      >
        <Text as="p" textStyle="small">
          Apellidos
        </Text>
        <Flex>
          <Text
            as="input"
            type="text"
            id="lastname"
            name="LNAME"
            placeholder="Apellidos"
            value={lastname.value}
            onChange={e => {
              setStatus({})
              setLastname({ ...lastname, value: e.target.value })
            }}
            width="100%"
          />
        </Flex>
        {!lastname.value && lastname.error && (
          <Box position="absolute" bottom="-24px" mt="xs">
            <Error>{lastname.error}</Error>
          </Box>
        )}
      </StyledInput>

      <StyledSelect
        as="label"
        htmlFor="organization"
        width={{ default: "100%", xs: "auto", s: "50%" }}
        pr={{ default: 0, xs: "m" }}
        mb="m"
      >
        <Text as="p" textStyle="small">
          Tipo de organización
        </Text>
        <Box
          as="select"
          id="organization"
          name="TYPEORG"
          width="100%"
          value={organization.value}
          onChange={e => {
            setStatus({})
            setOrganization({ ...organization, value: e.target.value })
          }}
          color={organization.value ? "text01" : "text06"}
        >
          <Text as="option" value={null}>
            Selecciona una opción
          </Text>
          {organizations.map(org => (
            <Text as="option" value={org} key={org}>
              {org}
            </Text>
          ))}
        </Box>
        {!organization.value && organization.error && (
          <Box position="absolute" bottom="-24px" mt="xs">
            <Error>{organization.error}</Error>
          </Box>
        )}
      </StyledSelect>

      <StyledInput as="label" htmlFor="phone" pr={{ default: 0, xs: "m" }} mb="m">
        <Text as="p" textStyle="small">
          Teléfono
        </Text>
        <Flex>
          <PhoneInput
            placeholder="Introducir teléfono"
            withCountryCallingCode={true}
            country="es"
            regions={["america", "europe"]}
            preferredCountries={["es", "fr", "pt", "de", "it"]}
            name="PHONE"
            localization={es}
            value={phone.value}
            isValid={false}
            inputStyle={{
              borderRadius: "0",
              width: "100%",
              height: `40px`,
              backgroundColor: `#AFD1E910`,
              boxShadow: "none",
              border: `1px solid #AFD1E9`,
              color: `#408ABF`,
              fontSize: "16px",
              fontFamily: "Work Sans",
            }}
            buttonStyle={{
              backgroundColor: `#AFD1E910`,
              border: `1px solid #AFD1E9`,
              borderRadius: "0",
            }}
            onChange={(v, d, e, formattedValue) => {
              setStatus({})
              setPhone({ ...phone, value: formattedValue, data: d, error: false })
            }}
          />
        </Flex>
        {phone.error && (
          <Box position="absolute" bottom="-24px" mt="xs">
            <Error>{phone.error}</Error>
          </Box>
        )}
      </StyledInput>

      <StyledInput as="label" htmlFor="email" width="100%" mb="m" pr={{ default: 0, xs: "m" }}>
        <Text as="p" textStyle="small">
          Correo electrónico
        </Text>
        <Flex>
          <Text
            as="input"
            type="email"
            id="email"
            name="EMAIL"
            placeholder="correo@dominio.com"
            value={email.value}
            onChange={e => {
              setStatus({})
              setEmail({ ...email, value: e.target.value })
            }}
            width="100%"
          />
        </Flex>
        {!email.value && email.error && (
          <Box position="absolute" bottom="-24px" mt="xs">
            <Error>{email.error}</Error>
          </Box>
        )}
      </StyledInput>

      <Flex
        width="100%"
        flexDirection={{ default: "column", s: "row" }}
        alignItems={{ default: "flex-start", m: "center" }}
        justifyContent={"space-between"}
        position="relative"
        pr={{ default: 0, xs: "m" }}
      >
        <Flex alignItems="center" justifyContent={"space-between"} mb={{ default: "l", m: 0 }}>
          <Text
            as="input"
            type="checkbox"
            id="rgpd"
            name="rgpd"
            mr="xxs"
            checked={consent.value}
            value={consent.value}
            onChange={_ => {
              setStatus({})
              setConsent({ ...consent, value: !consent.value })
            }}
          />
          <Text as="label" htmlFor="rgpd" textStyle="small" mr="xxs">
            Acepto la
          </Text>
          <SiteLink
            color="text01"
            href="/politica-privacidad"
            target="_blank"
            textStyle="small"
            css={`
              text-decoration: underline;
            `}
          >
            política de privacidad
          </SiteLink>
        </Flex>
        {!consent.value && consent.error && (
          <Box position="absolute" bottom="-24px" mt="xs">
            <Error>{consent.error}</Error>
          </Box>
        )}

        <Button type="submit" value="Enviar" icon="arrow" fullWidth={!breakpoints.s && true} />
      </Flex>
    </>
  )
}

const SendingResult = ({ error, success }) => {
  return (
    <Flex
      css={`
        border-top: 1px solid ${p => p.theme.color.ui05};
        border-bottom: 1px solid ${p => p.theme.color.ui05};
      `}
      width="100%"
      maxWidth="100%"
      py="5xl"
      justifyContent="center"
      position="relative"
    >
      <motion.div
        initial={{
          position: "absolute",
          top: "calc(50% - 60px)",
          left: "calc(50% - 60px)",
          opacity: 0.2,
        }}
        animate={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <svg height="100" width="100">
          <circle cx="50" cy="50" r="40" stroke-width="0" fill="#87BADE" />
        </svg>
      </motion.div>
      <motion.div
        initial={{
          position: "absolute",
          top: "calc(50% - 60px)",
          left: "calc(50% - 60px)",
          opacity: 0.2,
        }}
        animate={{ left: 0, opacity: 0.8 }}
        transition={{ delay: 1, duration: 0.5, staggerChildren: 0.5 }}
      >
        <Flex>
          <Box position="relative" as="div">
            <svg height="100" width="100">
              <circle cx="50" cy="50" r="30" stroke-width="0" fill="#87BADE" opacity="0.2" />
            </svg>
            <motion.div
              initial={{
                position: "absolute",
                top: "37.5%",
                left: "calc(50% - 10px)",
                scale: 1.2,
              }}
            >
              {success && (
                <svg width="21" height="16" fill="none" viewBox="0 0 21 16">
                  <motion.path
                    initial={{ pathLength: 0, opacity: 0 }}
                    animate={{ pathLength: 3, opacity: 1 }}
                    transition={{ delay: 1.5, duration: 0.5 }}
                    d="M1 8.5L6.5 14L19.5 1"
                    stroke={"#BEA382"}
                    strokeWidth="3"
                  />
                </svg>
              )}
              {error && (
                <svg width="21" height="16" viewBox="0 0 30 30" fill="none">
                  <motion.path
                    initial={{ pathLength: 0, opacity: 0 }}
                    animate={{ pathLength: 3, opacity: 1 }}
                    transition={{ delay: 1.5, duration: 0.5 }}
                    stroke="#BEA382"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.1869 29.9999L29.999 28.1882L16.8114 14.9998L29.9999 1.81234L28.1879 0.000500113L14.9995 13.1879L1.81236 0L0.0002439 1.81172L13.1875 14.9997L0 28.1862L1.81199 29.998L14.9994 16.8117L28.1869 29.9999Z"
                    fill="#B79367"
                  />
                </svg>
              )}
            </motion.div>
          </Box>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.7, duration: 0.3 }}
          >
            {error && (
              <Text as="p" textStyle="lead" color="textOnInverse05" mr="xl" ml="l" mt="s">
                Algo ha ido mal. Por favor, inténtalo más tarde o ponte en contacto con nosotros a
                través de{" "}
                <Text
                  css={`
                    cursor: pointer;
                  `}
                  color="textOnInverse05"
                  href="mailto:observatorio@secuoyas.com"
                  target="_blank"
                >
                  observatorio@secuoyas.com
                </Text>
              </Text>
            )}
            {success && (
              <Text as="p" textStyle="lead" color="textOnInverse05" mr="xl" ml="l" mt="s">
                Proceso completado correctamente. Recibirás un correo con un link para confirmar tu
                correo electrónico. ¡Gracias!
              </Text>
            )}
          </motion.div>
        </Flex>
      </motion.div>
      <motion.div
        initial={{
          position: "absolute",
          top: "calc(50% - 60px)",
          left: "calc(50% - 60px)",
          opacity: 0.2,
        }}
        animate={{ opacity: 0 }}
        transition={{ delay: 0.25, duration: 0.5 }}
      >
        <svg height="100" width="100">
          <circle cx="50" cy="50" r="10" stroke-width="0" fill="#87BADE" />
        </svg>
      </motion.div>
    </Flex>
  )
}
