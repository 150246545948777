import { AnimatePresence, motion } from "framer-motion"
import React, { forwardRef } from "react"
import styled from "styled-components"
import { MaxWidth, Text, Flex, Box } from "@sqymagma/elements"
import { Close } from "../IconSystem"
import MobileNavigation from "./MobileNavigation"
import SocialNetworks from "./SocialNetworks"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"

const StyledPanel = styled(motion.div)`
  height: 100vh;
  width: 100%;
  max-width: 380px;
  background-color: ${p => p.theme.color.primaryBackground};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 1px solid ${p => p.theme.color.line01};
  overflow: scroll;
  z-index: 99;
`
const variants = {
  open: { opacity: 1, x: 0, transition: { duration: 0.5, type: "tween", delay: 0.2 } },
  closed: { opacity: 1, x: "100%", transition: { duration: 0.5, type: "tween" } },
}

const Panel = forwardRef(({ isOpen, togglePanel }, panelRef) => {
  const breakpoints = useBreakpoint()
  return (
    !breakpoints.s && (
      <AnimatePresence initial={false}>
        <StyledPanel
          onClick={togglePanel}
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          initial={{ x: 0 }}
          ref={panelRef}
        >
          <MaxWidth py={{ default: "l", m: "xl" }} position="relative" height="100%">
            <Flex width="100%" height="100%" flexDirection={{ default: "column", m: "row" }}>
              <Box width={{ default: 1, m: 3 / 12 }} pb={{ default: "m", s: 0 }}>
                <Text
                  mr="xxs"
                  arial-label="Cerrar menu"
                  onClick={togglePanel}
                  position="absolute"
                  top="32px"
                  right="0"
                  textStyle="smallBold"
                  css={`
                    cursor: pointer;
                  `}
                >
                  <Close height="32px" />
                </Text>
              </Box>

              <MobileNavigation mb="l" />
              <SocialNetworks />
            </Flex>
          </MaxWidth>
        </StyledPanel>
      </AnimatePresence>
    )
  )
})

export default Panel
