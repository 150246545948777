import React, { useState, useRef } from "react"
import { Box } from "@sqymagma/elements"
import Seo from "./Seo"
import PropTypes from "prop-types"
import FooterSimple from "./FooterSimple"
import FooterComposed from "./FooterComposed"
import Header from "./Header"
import Panel from "./Panel"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Layout = ({
  children,
  revealedFooter,
  footerSimple,
  inverseHeader,
  seoTitle,
  seoDescription,
  seoImage,
  seoImageAlt,
  noFooter,
  ...props
}) => {
  const breakpoints = useBreakpoint()
  const [isPanelOpen, openPanel] = useState(false)
  const panelRef = useRef()
  const togglePanel = () => {
    if (panelRef.current) {
      panelRef.current.scrollTop = !isPanelOpen ? 0 : panelRef.current.scrollTop
    }
    openPanel(!isPanelOpen)
  }
  const layoutForRevealedFooter = revealedFooter && !footerSimple && breakpoints.s

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        imageAlt={seoImageAlt}
        bodyAttributes={
          isPanelOpen && !breakpoints.s
            ? {
                class: "is-panel-open",
              }
            : {}
        }
      />
      <Box
        {...props}
        as="main"
        bg="primaryBackground"
        css={
          layoutForRevealedFooter &&
          `          
          position: relative;
          z-index: 1;
          box-shadow: ${breakpoints.m && `0 0 40px rgba(0,0,0, .15)`};
          `
        }
        maxWidth="100vw"
      >
        <Panel isOpen={isPanelOpen} togglePanel={togglePanel} panelRef={panelRef} />
        <Header inverseHeader={inverseHeader} togglePanel={togglePanel} isPanelOpen={isPanelOpen} />
        {children}
      </Box>
      {!noFooter && (
        <Box
          css={
            layoutForRevealedFooter &&
            `
          position: ${breakpoints.m ? "sticky" : "relative"};
          bottom: 0;
          left: 0;
          width: 100%;
        `
          }
          overflowX="hidden"
        >
          {!footerSimple ? <FooterComposed /> : <FooterSimple />}
        </Box>
      )}
    </>
  )
}

export default Layout

Layout.defaultProps = {
  seoDescription: null,
  seoTitle: null,
  seoImage: null,
  seoImageAlt: null,
}

Layout.propTypes = {
  seoTitle: PropTypes.string.isRequired,
  seoDescription: PropTypes.string.isRequired,
  seoImage: PropTypes.string.isRequired,
  seoImageAlt: PropTypes.string.isRequired,
}
