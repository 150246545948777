import React from "react"
import styled, { css } from "styled-components"
import { Box, Button as MagmaButton, Stack, Text } from "@sqymagma/elements"
import { theme } from "@sqymagma/theme"
import Icon from "../IconSystem"

const Button = ({
  children,
  value,
  appareance = "primary",
  icon,
  href,
  to,
  newTab,
  noFollow,
  fullWidth,
  ...props
}) => {
  return (
    <StyledButton
      appareance={appareance}
      href={!props.disabled ? href : null}
      to={!props.disabled ? to : null}
      as={href ? "a" : "button"}
      target={newTab ? "_blank" : null}
      fullWidth={fullWidth}
      {...props}
    >
      <Stack alignItems="center" justifyContent="space-between" hs={fullWidth ? "5xl" : "4xl"}>
        {children && (
          <Text crop textStyle="tinyBold" caps>
            {children}
          </Text>
        )}
        {value && (
          <Text crop textStyle="tinyBold" caps>
            {value}
          </Text>
        )}

        {icon && (
          <IconPlace>
            <Icon name={`${icon}`} height="13px" />
          </IconPlace>
        )}
      </Stack>
    </StyledButton>
  )
}

const IconPlace = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: ${theme("spacing.button.iconSize")};
    height: ${theme("spacing.button.iconSize")};
  }
`

const Focus = css`
  outline: 2px solid ${p => p.theme.color.interactive01Focus};
`

const Primary = css`
  color: ${theme("color.textOnInteractive01")};
  background-color: ${theme("color.interactive01")};

  svg * {
    fill: ${theme("color.textOnInteractive01")};
  }

  &:hover {
    background-color: ${theme("color.interactive01Hover")};
    color: ${theme("color.textOnInteractive01Hover")};
  }

  &:active {
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    color: ${theme("color.textInteractive01Active")};
    background-color: ${theme("color.interactive01Active")};
  }
`
const PrimaryDisabled = css`
  color: ${theme("color.disabled02")};
  background-color: ${theme("color.disabled01")};

  svg * {
    fill: ${theme("color.disabled02")};
  }
`

const StyledButton = styled(MagmaButton)`
  display: flex;
  align-items: center;
  justify-content: center ;
  border: 0;
  transition: background-color 0.5s ease, box-shadow 0.5s ease,  color 0.5s ease, color 0.5s ease, fill 0.5s ease;
  white-space: nowrap;
  cursor: pointer;

  /* Main size properties */
  width: ${p => (p.fullWidth ? "100%" : "200px")};
  height: ${theme("spacing.button.height")};
  padding-left: ${theme("spacing.button.paddingX")};
  padding-right: ${theme("spacing.button.paddingX")};

  /* Focus */
  &:focus { 
      ${Focus} 
    }
  ${p => p.focus && Focus}

/* Disabled */
  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
    `}

    ${p => p.appareance === "primary" && !p.disabled && Primary}
    ${p => p.appareance === "primary" && p.disabled && PrimaryDisabled}


  svg * {
    transition: fill 0.5s ease;
  }
`

export default Button
