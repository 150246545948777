import { Box, Flex, MaxWidth } from "@sqymagma/elements"
import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { ObservatorioLogo, OpenMenu } from "../IconSystem"
import Navigation from "./Navigation"
import WrapperLink from "./WrapperLink"

const Header = ({ inverseHeader, togglePanel }) => {
  const breakpoints = useBreakpoint()

  return (
    <Box
      as="header"
      position="absolute"
      top="0"
      zIndex="9"
      overflow="hidden"
      width="100vw"
      overflowX="hidden"
    >
      <MaxWidth pt={{ default: "l", m: "xxl" }} pb="m">
        <Flex justifyContent="space-between" alignItems="center">
          <WrapperLink to="/" aria-label="Observatorio logo">
            <ObservatorioLogo
              height={{ default: "32px", m: "40px" }}
              ml="-8px"
              alt="Observatorio logo"
              fill={inverseHeader ? "textOnInverse01" : null}
            />
          </WrapperLink>
          {breakpoints.s && (
            <Navigation inverseHeader={inverseHeader} mr={{ default: "m", s: 0 }} />
          )}
          {!breakpoints.s && (
            <OpenMenu
              fill="ui01"
              height="24px"
              onClick={togglePanel}
              css={`
                cursor: pointer;
              `}
            />
          )}
        </Flex>
      </MaxWidth>
    </Box>
  )
}

export default Header
