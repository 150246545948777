import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, title, image, imageAlt, bodyAttributes }) => {
  const { pathname } = useLocation()
  const cloudinaryUrl =
    "https://res.cloudinary.com/thesaurus-cms/image/upload/v1606157949/observatorio/"
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle: title
          titleTemplate
          siteDescription: description
          siteUrl: url
          twitterUsername
          siteImage: image
          siteImageAlt: imageAlt
          author
        }
      }
    }
  `)

  const {
    siteTitle,
    titleTemplate,
    siteDescription,
    siteUrl,
    twitterUsername,
    siteImage,
    siteImageAlt,
    author,
  } = site.siteMetadata

  const seo = {
    title: title || siteTitle,
    description: description || siteDescription,
    image: image ? cloudinaryUrl + image : cloudinaryUrl + siteImage,
    imageAlt: imageAlt || siteImageAlt,
    url: `${siteUrl}${pathname}`,
    siteTitle,
    titleTemplate: pathname !== "/" ? titleTemplate : "Observatorio | Educación Superior",
  }

  return (
    <Helmet
      htmlAttributes={{ lang: "es" }}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      bodyAttributes={bodyAttributes}
    >
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.title && <meta name="title" property="og:title" content={seo.title} />}
      {seo.siteTitle && <meta property="og:site_name" content={seo.siteTitle} />}
      {seo.description && (
        <meta name="description" property="og:description" content={seo.description} />
      )}
      <meta name="author" content={author} />
      {seo.image && <meta name="image" property="og:image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && <meta name="twitter:site" content={twitterUsername} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.imageAlt && <meta name="twitter:image:alt" content={seo.imageAlt} />}
    </Helmet>
  )
}

Seo.defaultProps = {
  description: null,
  title: null,
  image: null,
  imageAlt: null,
  bodyAttributes: {},
  author: null,
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  bodyAttributes: PropTypes.object,
  author: PropTypes.string,
}

export default Seo
