import React from "react"
import { Stack } from "@sqymagma/elements"
import Icon from "../IconSystem"
import { networks } from "../../data"
import SiteLink from "./SiteLink"

const SocialNetworks = props => {
  return (
    <Stack hs="m" {...props}>
      {networks.map(item => (
        <SiteLink
          href={`${item.url}`}
          key={item.name}
          arial-label={item.name}
          name={item.name}
          css={`
            svg {
              path {
                transition: all ease-in 0.25s;
              }
              &:hover path {
                fill: ${p => p.theme.color[p.name]};
              }
            }
          `}
        >
          <Icon name={item.name} height="16px" alt={item.name} title={item.name} />
        </SiteLink>
      ))}
    </Stack>
  )
}
export default SocialNetworks
