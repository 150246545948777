import React from "react"
import { Stack, Text, Box, Flex } from "@sqymagma/elements"
import LetsMetForm from "./LetsMetForm"
import { letsMetForm } from "../../data"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const StyledBgImage = styled(BackgroundImage)`
  background-position: top center;
  max-width: 320px;
`

const LetsMetBanner = props => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(name: { regex: "/lets-met-form/" }) {
        relativePath
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Flex
      id="newsletter"
      position="relative"
      overflow="hidden"
      alignItems="center"
      minHeight={{ default: "480px", xxl: "800px" }}
      height={props.height || "auto"}
      {...props}
      css={`
        border-top: 1px solid ${p => p.theme.color.line01};
      `}
    >
      <Box width={3 / 12} display={{ default: "none", m: "block" }}>
        <StyledBgImage fluid={image.sharp.fluid}>
          <Box height="100vh" width="100%" position="relative" />
        </StyledBgImage>
      </Box>
      <Flex
        height="fit-content"
        width={{ default: "100%", m: 9 / 12 }}
        justifyContent="center"
        py={props.px || { default: "xl", m: "3xl" }}
        px={{ default: "m", s: "xxl" }}
      >
        <Box maxWidth={{ m: "88%" }}>
          <Stack vs="xs" flexDirection="column" mb="xl">
            <Text as="p" textStyle="smallBold" color="text02" caps>
              {letsMetForm.tag}
            </Text>
            <Text as="h2" textStyle="title03" color="text01">
              {letsMetForm.title}
            </Text>
            <Text as="p" textStyle="body">
              {letsMetForm.description}
            </Text>
          </Stack>

          <LetsMetForm />
        </Box>
      </Flex>
    </Flex>
  )
}

export default LetsMetBanner
