import { Box, Flex, MaxWidth, Text } from "@sqymagma/elements"
import React from "react"
import styled from "styled-components"
import { ObservatorioLogo } from "../IconSystem"
import SiteLink from "./SiteLink"
import SocialNetworks from "./SocialNetworks"

const BackgroundFooter = styled(Box)`
  background: ${p =>
    !!p.inverse ? p.theme.gradient.bannerBackground : p.theme.gradient.primaryBackground};
`
const FooterSimple = ({ inverse }, props) => {
  return (
    <BackgroundFooter as="footer" inverse={inverse} {...props} overflow="hidden" width="100vw">
      <MaxWidth
        css={`
          padding-bottom: ${p => p.theme.spacing.s};
          &:before {
            content: "";
            margin-left: -32px;
            margin-right: -32px;
            border-top: 1px solid ${p => p.theme.color.line01};
            padding-bottom: ${p => p.theme.spacing.m};
          }
        `}
      >
        <Flex
          justifyContent="space-between"
          flexDirection={{ default: "column", xs: "row" }}
          alignItems={{ default: "center", xs: "flex-start" }}
          flexWrap="wrap"
        >
          <Flex
            alignItems="center"
            width={{ default: "100%", xs: "50%", m: "auto" }}
            justifyContent="flex-start"
            flexDirection={{ default: "row", m: "row" }}
            mb={{ default: "xxs", m: 0 }}
          >
            <SiteLink to="/" mr="xxs" arial-label="Observatorio logo" mb={{ mb: "2px", m: 0 }}>
              <ObservatorioLogo
                height="24px"
                ml={{ default: "0", m: "-8px" }}
                alt="Observatorio logo"
                title="Observatorio logo"
                fill={inverse ? "textOnInverse01" : null}
              />
            </SiteLink>
            <Text
              textStyle="tinyAlt"
              color={inverse ? "textOnInverse01" : null}
              pb="6px"
              css={`
                white-space: nowrap;
              `}
            >
              © 2020
            </Text>
          </Flex>
          <Flex
            alignItems={{ default: "flex-start", s: "center" }}
            width={{ default: "100%", xs: "auto", m: "auto" }}
            justifyContent="flex-start"
            flexDirection={{ default: "column", s: "row" }}
            css={`
              > nav a {
                white-space: nowrap;
              }
              > nav a:not(:last-of-type):after {
                content: "|";
                margin: 0 ${p => p.theme.spacing.s};
              }
            `}
          >
            <Flex as="nav">
              <SiteLink
                to="/politica-privacidad"
                textStyle="tinyAlt"
                color={inverse ? "textOnInverse01" : "text01"}
              >
                Política de privacidad
              </SiteLink>
              <Text
                as="a"
                textStyle="tinyAlt"
                mr="m"
                color={inverse ? "textOnInverse01" : "text01"}
              >
                observatorio@secuoyas.com
              </Text>
            </Flex>
            <SocialNetworks mt={{ default: "s", s: 0 }} />
          </Flex>
        </Flex>
      </MaxWidth>
    </BackgroundFooter>
  )
}

export default FooterSimple
