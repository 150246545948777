export const networks = [
  // { name: "twitter", url: "https://twitter.com/secuoyas" },
  // { name: "instagram", url: "https://www.instagram.com/secuoyas/" },
  // { name: "medium", url: "https://medium.com/secuoyas" },
  { name: "linkedin", url: "https://www.linkedin.com/showcase/0bservatorio/" },
]

export const navigation = [
  { label: "Inicio", url: "/", internal: true },
  { label: "Misión", url: "/mision", internal: true },
  { label: "Trends 2020", url: "/trends-2020", internal: true },
]

export const newsletterForm = {
  title: "Newsletter",
  description: "Déjanos tu correo electrónico y te mantendremos informado de todas las novedades",
}

export const letsMetForm = {
  tag: "Siguientes pasos",
  title: "¿Has identificado alguno de tus problemas?",
  description:
    "Disponemos de mucho más trabajo de investigación que no cabía en este Trend. Estos y otros muchos retos en la Educación Superior necesitan de trabajo conjunto y mucha colaboración. Déjanos tus datos y nos pondremos en contacto contigo",
}

export const homePage = {
  missionTitle: "El observatorio de la Educación Superior",
  missionDescription:
    "La **Educación Superior** es uno de los pilares de cualquier sociedad avanzada, sin embargo no siempre caminan a la misma velocidad. A lo largo de las últimas décadas, el sector educativo ha tenido una evolución marcada por el contraste. Mientras unas instituciones se mantenían inmóviles, con unos procedimientos y una oferta educativa que se mantenía estanca, otras formaciones e instituciones surgían al ritmo que marcaba el mercado laboral en un intento dar respuesta a las nuevas demandas de conocimiento y empleo.",
  missionUrl: "/mision",
}

export const trendsPage = {
  title: "Trends 2020",
  description:
    "El Observatorio ha realizado una prospección y selección de los temas que creemos van a tener más peso en el futuro cercano de la Educación Superior, que pueden convertirse en líneas de trabajo y de evolución en las instituciones educativas.",
}

export const missionPage = {
  title: "El Observatorio de la Educación Superior",
  description:
    "Las Trends 2020 son un acercamiento a los incipientes cambios que se están dando en el sector educativo: En qué consisten, qué riesgos conllevan, cuáles son sus beneficios y qué preguntas nos despiertan.",
  tag: "Misión",
  body: [
    {
      type: "text",
      content:
        "La Educación Superior **es uno de los pilares de cualquier sociedad avanzada**, sin embargo no siempre caminan a la misma velocidad. A lo largo de las últimas décadas, el sector educativo ha tenido una evolución marcada por el contraste. Mientras unas instituciones se mantenían inmóviles, con unos procedimientos y una oferta educativa que se mantenía estanca, otras formaciones e instituciones surgían al ritmo que marcaba el mercado laboral en un intento dar respuesta a las nuevas demandas de conocimiento y empleo.",
    },
    {
      type: "text",
      content:
        "Con un mercado laboral sacudido y una sociedad haciendo malabares para mantenerse en equilibrio, la educación debe ahora someterse a cuestionamiento. Por un lado, extrínseco, ¿cuál debe ser el papel de la educación y qué debe aportar a nuestra sociedad? Por otro, intrínseco a cada institución, ¿cuál es el valor que generan y cómo deben proceder para evitar pérdidas?",
    },
    {
      type: "text",
      content:
        "Este año hemos tomado conciencia de **la importancia de dimensionar adecuadamente el aspecto tecnológico en la educación**. Las instituciones necesitan desarrollar una visión de futuro que les permita tomar decisiones estratégicas que vayan más allá del estar a la altura de las circunstancias. Algo que puede resultar especialmente complicado cuando se multiplican los aspectos a tener en cuenta y eso es, precisamente, lo que ha traído la cuestión de estudiar en remoto.",
    },
    {
      type: "quote",
      content:
        "Con un mercado laboral sacudido y una sociedad haciendo malabares para mantenerse en equilibrio, la educación debe ahora someterse a cuestionamiento",
    },
    {
      type: "text",
      content:
        "Hemos pasado, por tanto, **de un escenario caótico a uno complejo donde las soluciones no son globales**, nose pueden replicar de una institución a otra, pero donde sí estamos viendo como algunasprácticas surgidas en este período se están asentando y empiezan a considerarse básicas. Haymucho trabajo por delante y muchas oportunidades de evolución de este sector esencial.",
    },
    {
      type: "text",
      content:
        "Por ello, **hemos creado el Observatorio de Educación Superior**. Un espacio que queremos que actúe como lugar de debate, divulgación, diálogo y encuentro, donde compartiremos conocimiento, puntos de vista y herramientas **para construir juntos un ecosistema de trabajo que acerque a expertos y profesionales** de todos las materias relacionadas con la Educación Superior.",
    },
    {
      type: "text",
      content:
        "Como punto de partida, el Observatorio ha realizado **una prospección y selección de los temas que creemos van a tener más peso en el futuro cercano** de la Educación Superior: En qué consisten, qué riesgos conllevan, cuáles son los beneficios y qué preguntas nos despiertan esas tendencias que pueden convertirse en líneas de trabajo y de evolución en las instituciones educativas.",
    },
  ],
}

export const privacyPage = {
  title: "Política de privacidad",
  body: [
    {
      type: "text",
      content:
        "Secuoyas Experience se esfuerza por proteger tu privacidad de forma confiable y pretendemos ser transparentes al explicar claramente cómo recogemos y procesamos tu información. Es importante para nosotros que disfrutes de la web de Observatorio sin comprometer tu privacidad de ninguna forma. Tienes derecho a acceder, modificar o eliminar tu información personal en cualquier momento. También puedes ponerte en contacto con nosotros a través de la sección de contacto en nuestra web corporativa.",
    },
    { type: "title", content: "Recopilación y uso de tu información" },
    {
      type: "text",
      content:
        "La única información personal que se puede llegar a recoger en observatorio es tu nombre y tu dirección de correo electrónico. Esta información se recoge de forma voluntaria y la almacenaremos, única y exclusivamente, si tú decides proporcionarla para informarte de las novedades y actividades de Observatorio.",
    },
    {
      type: "text",
      content:
        "No usamos tu información personal para ningún otro propósito comercial, ni de ninguna otra índole, ni la compartimos con nadie, bajo ningún concepto.",
    },
    {
      type: "text",
      content:
        "No rastreamos ni recopilamos ninguna información sensible sobre ti. Sólo la estrictamente mencionada anteriormente.",
    },
    {
      type: "title",
      content: "Almacenamiento seguro de tu información",
    },
    {
      type: "text",
      content:
        "Nos tomamos muy en serio la seguridad de la información y tenemos políticas y procedimientos para asegurarnos de que la información que tenemos sobre ti permanezca segura. Limitamos quién tiene acceso a tu información y nos aseguramos de que aquellos que lo hacen están obligados por contratos a proceder de forma segura y restringida.",
    },
    {
      type: "text",
      content:
        "Almacenamos tu información de forma segura y la guardamos durante el tiempo que sea necesario para prestar nuestros servicios de acuerdo con la ley.",
    },
    {
      type: "text",
      content:
        "Si nos solicitas que dejemos de ponernos en contacto contigo, procederemos a eliminar tus datos por completo.",
    },
    { type: "title", content: "Contacto" },
    {
      type: "text",
      content:
        "Nuestro representante de privacidad es Javier Loureiro Varela. Si tienes preguntas sobre esta política o sobre tu información personal, por favor envía un correo electrónico a [privacidad@secuoyas.com](mailto:privacidad@secuoyas.com)",
    },
    {
      type: "text",
      content:
        "Alternativamente, puedes escribirnos por correo postal a la siguiente dirección: Secuoyas Experience, S.L. Botánica, 4, 2ª Planta 28028 Madrid España",
    },
    {
      type: "text",
      content: "También puedes llamarnos por teléfono al +34 915 613 719",
    },
  ],
}
