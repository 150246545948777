import React from "react"
import { Link } from "gatsby"

const WrapperLink = ({ children, ...props }) => (
  <Link
    css={`
      line-height: 0;
    `}
    {...props}
  >
    {children}
  </Link>
)

export default WrapperLink
